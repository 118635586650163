.footer {
  justify-self: flex-end;
  margin: 1.875rem 0;
  padding-top: 0.625rem;
}

.footer__navigation {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.875rem;
  padding: 0;
  margin: 0;
}

#exit .button__label {
  color: #FF453A;
}
