.master-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.875rem;
  margin: 0 auto;
  width: 20.5rem;
  min-width: 20.5rem;

  color: #8E7FF7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.0255rem;
}

.master-form__form {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: stretch;
  padding: 0 0 0.3125rem;
  margin: 0;
  width: 100%;
}

.master-form__fieldset {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: stretch;
  gap: 1.25rem;
  padding: 0;
  margin: 0;
  width: 100%;

  text-align: center;
  border-style: none;
}

.master-form__title {
  padding-bottom: 1.25rem;

  color: #FFF;
  font-size: 1.5rem;
  font-weight: 500;
}

.master-form__note {
  color: #9197A6;
}

.master-form__input {
  width: 100%;
  height: 3.125rem;
  background-color: #FFF;
  border-radius: 0.625rem;
  border: 1px solid #D9D9D9;

  color: #8E7FF7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'SF Pro Rounded', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.0255rem;

  padding: 0.875rem 1rem;
  margin: 0;
  display: inline-block;

  -webkit-transition: 0.2s;
  transition-duration: 0.2s;
  outline: none;
}

.master-form__input:focus {
  border: 2px solid #D9D9D9;
  background-color: #f1f4f0;
}

::placeholder {
  color: #8E7FF7;
  opacity: 1; /* Firefox */
}

.master-form__input_error {
  border-color: #FF453A;
}

.master-form__error-message {
  width: 100%;
  padding-top: 0.3125rem;

  text-align: left;
  float: left;
  color: #FF453A;
  font-size: 0.875rem;
}

.master-form__error-message_visible {
  display: inline;
}

.master-form__error-message_hidden {
  display: none;
}

.master-form__button {
  width: 100%;
  height: 3.125rem;
  background-color: #8E7FF7;
  border: none;
  border-radius: 0.625rem;
  padding: 0.875rem 1.25rem;
  margin: 0;

  color: white;
  font-feature-settings: 'case' on;
  font-family: 'SF Pro Rounded', sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.0255rem;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.2s;
}

.master-form__button:hover {
  opacity: 0.7;
}

.master-form__button:active {
  background-color: #7c6de3;
  box-shadow: 0 -1px #777;
  transform: translateY(1px);
}

.master-form__button_alternative {
  background-color: white;
  border: 2px solid #8E7FF7;
  margin-top: 0.3125rem;

  color: #8E7FF7;
}

.master-form__button_alternative:hover {
}

.master-form__button_alternative:active {
  background-color: white;
  opacity: 0.8;
}

.master-form__link {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.2s;
}

.master-form__link:hover {
  text-decoration: underline;
  opacity: 0.8;
}

.master-form__link:active {
  opacity: 0.9;
  transform: translateY(1px);
}
