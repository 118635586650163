.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.875rem;
  padding: 0;
  margin: 0;
  transition: all step-end 100ms;
}

.header_logged-out {
  padding: calc(17vh - 3.125rem) 0 1.875rem;
  width: 20.5rem;
  min-width: 20.5rem;
}

.header__logo {
  width: 9.16475rem;
  height: 2.5rem;
  pointer-events: none;
  transition: all ease-in 150ms;
}

.header__logo_logged-out {
  width: 18.75rem;
  height: 5.11469rem;
}

.header__personal-information {
  display: flex;
  width: 17.1875rem;
  justify-content: space-between;
  align-items: flex-start;
}

.header__avatar-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9375rem;
}

.header__avatar-name {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  width: 0.937rem;
}

.header__avatar {
  width: 4.375rem;
  height: 4.375rem;
  flex-shrink: 0;
}

.header__name {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00438rem;
}

.header__email {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00394rem;
}

.balance {
  display: flex;
  width: 17.1875rem;
  height: 6.25rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  flex-shrink: 0;

  border-radius: 0.625rem;
  background: linear-gradient(90deg, #07C269 0%, #07BF6B 29%, #07B575 50%, #07A684 68%, #07909A 84%, #0774B7 100%);
}

.balance_title {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00438rem;
}

.balance__amounts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.balance__tokens {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00656rem;
}

.balance__travelincoin {
  width: 0.90275rem;
  height: 1.25rem;
}

.balance__money {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0035rem;
}
