.App {
  min-height: 100vh;
  max-height: 69.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.875rem;
  /*justify-content: flex-start;*/
  /*align-items: flex-start;*/
  padding: 3.125rem 3.125rem 3.125rem 2.5rem;

  border-radius: 2.5rem;
  background-color: #101010;
}

.App_logged-out {
  align-items: center;
}
