.sidebar {
  margin-bottom: 1.875rem;
}

.sidebar__navigation {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.875rem;
  padding: 0 0.6255rem;
  margin: 0;
}
