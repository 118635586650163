.downloads {
  display: flex;
  width: 58.4375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

.download__item {
  display: flex;
  width: 88.4375rem;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.download__title {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 122.222% */
  letter-spacing: -0.0255rem;
}

.download__icon {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

.knowledge-base {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 22.2rem - 3.125rem - 7.5rem);
  gap: 1.25rem;
  flex-grow: 1;
  /*flex-shrink: 10;*/
  flex-basis: auto;
}

.knowledge-base__title {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.00438rem;

  padding-bottom: 0.3125rem;
  width: 100%;
}

.tg-bot-hint {
  width: 59.0625rem;
  height: 8rem;
  flex-shrink: 0;
  align-self: center;

  border-radius: 1.25rem;
  background: #FFF;

  padding: 1.5625rem;
  margin-bottom: 3.3125rem;
}

.tg-bot-hint__title {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.00656rem;

  margin-bottom: 1.25rem;
}

.tg-bot-hint__text {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.0255rem;
}
