.main-content {
  order: 10;
  min-width: calc(100vw - 22.2rem - 3.125rem);
  /*width: calc(100vw - 22.2rem - 3.125rem);*/
  min-height: 53.5rem;
  flex-grow: 0;
  border-radius: 1.875rem;
  background: #F6F7F9;
  padding: 2.5rem 3.125rem;
  margin-left: 0.625rem;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.main-content__header {
  display: inline-flex;
  align-items: center;
  gap: 1.25rem;
  /*margin-bottom: 2.5rem;*/
}

.main-content__icon {
  width: 2.5rem;
  height: 2.5rem;
}

.main-content__title {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.00656rem;

  margin: 0;
}

.avatar-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  /*margin-bottom: 2.5rem;*/
}

.avatar-control__image {
  width: 8.75rem;
  height: 8.75rem;
  flex-shrink: 0;

  border-radius: 6.25rem;
  /*background: var(--Alex-Goodman, url(<path-to-image>), lightgray 50% / cover no-repeat);*/
}

.avatar-control__button {
  display: flex;
  align-items: center;
  gap: 0.3125rem;

  color: #7966FA;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0035rem;

  cursor: pointer;
}

.avatar-control__plus-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.profile-form {
  display: flex;
  justify-content: stretch;
  gap: 15.94rem;
}

.profile-form__section {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.25rem;

  border-style: none;
  padding: 0;
  margin: 0;
}

.profile-form__section-title {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00438rem;

  padding-left: 0;
  margin-bottom: 1.25rem;
}

.profile-form__field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.profile-form__field-label {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0035rem;
}

.profile-form__field-label_asterisk {
  color: #7966FA;
  /*font-family: SF Pro Rounded;*/
  /*font-size: 1rem;*/
  /*font-style: normal;*/
  font-weight: 600;
  /*line-height: normal;*/
  /*letter-spacing: 0.0035rem;*/
}

.profile-form__field-input {
  width: 23.75rem;
  height: 3.125rem;
  flex-shrink: 0;

  border-radius: 0.625rem;
  border: 1px solid #D9D9D9;
  background: #FFF;

  color: #8E7FF7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'SF Pro Rounded', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 137.5% */
  letter-spacing: -0.0255rem;

  padding: 0.875rem 1rem;
  margin: 0;
}

.profile-form__field-input::placeholder {
  color: #DADADA;
  opacity: 1; /* Firefox */
}

.profile-form__change-controls {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;
  margin-top: 0.625rem;
  margin-bottom: 2.5rem;
}

.profile-form__change-block {
  display: flex;
  width: 23.75rem;
  justify-content: space-between;
  align-items: center;
}

.profile-form__change-label {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00438rem;
}

.profile-form__change-button {
  color: #7966FA;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0035rem;

  cursor: pointer;
}

.profile-form__submit-button {
  display: flex;
  width: 11.875rem;
  height: 3.125rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;

  border-radius: 0.625rem;
  border: 1px solid #000;
  background: radial-gradient(129.22% 129.17% at 50.2% -29.17%, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%);

  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00438rem;

  cursor: pointer;
}
