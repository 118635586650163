.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;

  transition-duration: 0.1s;
}

.button:hover {
  opacity: 0.7;
}

.button:active {
  opacity: 0.9;
  transform: translate(1px, 1px);
}

.button_active {
  /*display: flex;*/
  justify-content: flex-start;
  width: 17.1875rem;
  height: 3.75rem;
  padding: 0.625rem 1.875rem;
  /*align-items: center;*/
  /*gap: 0.625rem;*/

  border-radius: 0.625rem;
  background-color: #8E7FF7;

  cursor: default;
}

.button_active:hover {
  opacity: 1;
}

.button_active:active {
  opacity: 1;
  transform: translate(0, 0);
}

.button__icon {
  width: 1.875rem;
  height: 1.875rem;
}

.button__label {
  color: #FFF;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00438rem;
}
