@import url("https://db.onlinewebfonts.com/c/060fd297f19446447a9a1325ad5b889b?family=SF+Pro+Rounded&display=swap");

body {
  margin: 0;
  font-family: 'SF Pro Rounded', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

* {
  box-sizing: border-box;
}
