.knowledge-base_accordion {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.knowledge-base_item {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 88.4375rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #DADADA;
}

.knowledge-base_item:last-child {
  border-bottom: 0;
}

.knowledge-base__question-container {
  display: flex;
  width: 88.4375rem;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.knowledge-base__question {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.00394rem;

  /*width: 50%;*/
}

.knowledge-base__item-button {
  width: 1.25rem;
  height: 0.625rem;
  flex-shrink: 0;

  stroke-width: 1px;
  stroke: #D4D4D4;
}

.knowledge-base__answer {
  color: #000;
  /*font-family: SF Pro Rounded;*/
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.0035rem;

  width: 100%;
  flex-shrink: 1;
}
